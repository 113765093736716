import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Stack } from 'elements/components';
import { RecipientCustodialAccount } from 'elements/types';
import { getAccountData } from 'elements/element-transfer/utils/getAccountData';

type Props = {
  data?: RecipientCustodialAccount;
};

const RadioLabel: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const { number = '', id, type } = data;
  const name = data.name || data.ownerIdentityDisplayName || getAccountData(type).title;
  const fomattedAccountNumber = `*${number.slice(-4)}`;
  const formattedName = name.length > 35 ? `${name?.substr(0, 35)}...` : name;

  return (
    <Stack direction="row" alignCenter justifyContent="space-between">
      <Stack paddingY="5px">
        <Typography variant="bodyDefaultBook">
          {formattedName} | {t('elements.transfer.internalTransferRecipientSearchPage.endingIn')}{' '}
          {fomattedAccountNumber}
        </Typography>
        <Typography variant="bodyDefaultBook" color="secondary.font">
          {id}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default RadioLabel;
