import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Stack } from 'elements/components';
import { ExternalAccount } from 'elements/types';
import { ReactComponent as CrossIcon } from 'assets/icons/remove-x-red.svg';

type Props = {
  data?: ExternalAccount;
  onDelete?: (id: string) => void;
};

const RadioLabel: React.FC<Props> = ({ data, onDelete }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const { name = '', last4, id } = data;

  const formattedName = name.length > 35 ? `${name.substr(0, 35)}...` : name;

  const onDeleteClick = () => {
    if (id && onDelete) {
      onDelete(id);
    }
  };

  return (
    <Stack direction="row" alignCenter justifyContent="space-between">
      <Stack paddingY="5px">
        <Typography variant="bodyDefaultBook">
          {formattedName} | {t('elements.transfer.withdrawalWireRecipientSearchPage.endingIn')} *{last4}
        </Typography>
        <Typography variant="bodyDefaultBook" color="secondary.font">
          {id}
        </Typography>
      </Stack>
      {onDelete && (
        <IconButton onClick={onDeleteClick}>
          <CrossIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default RadioLabel;
