import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Backdrop, Typography, useTheme } from '@mui/material';
import useForm from 'elements/hooks/useForm';
import { Button, NumberInput } from 'elements/components';
import { ErrorState } from 'elements/features/LoadingErrorState';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';
import { WalletAddressSection } from 'elements/element-transfer/components/Withdrawal/WalletAddressSection';
import { useYupRules } from 'elements/element-transfer/hooks/useYupRules';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { OnCloseElement } from 'elements/models/types/element-result';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { getUniqueIdentifierForCustodialAccountBalanceModel } from 'utils/account-balances/get-unique-identifier-for-custodial-account-balance-model';
import { CoinsDropdown, renderSelectedValue } from 'elements/element-transfer/components/CoinsDropdown';
import { StyledContainer } from './styled';

type Props = {
  amount: string;
  selectedCoin: CustodialAccountBalanceModel | null;
  coinList: CustodialAccountBalanceModel[];
  walletAddress: string;
  destinationTag: string;
  getCoinListError?: boolean;
  lockAmountError: boolean;
  unlockAmountError: boolean;
  lockingAmount: boolean;
  onReview: (formValues: { amount: string; walletAddress: string; destinationTag: string; cabId: string }) => void;
  onReload: () => void;
  onBack: () => void;
  onClose: OnCloseElement;
  clearLockError?: () => void;
};

const CryptoAmountPage: React.FC<Props> = ({
  amount,
  selectedCoin,
  coinList,
  walletAddress,
  destinationTag,
  getCoinListError,
  unlockAmountError,
  lockAmountError,
  lockingAmount,
  onReview,
  onReload,
  onBack,
  onClose,
  clearLockError,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const theme = useTheme<ElementsCustomTheme>();

  const {
    cryptoWithdrawalAmountRule,
    cryptoWithdrawalWalletAddressRule,
    requiredStringRule,
    requiredNumberRule,
    destinationTagRule,
  } = useYupRules();

  const {
    formik: { setFieldValue, setValues, submitForm, values, validateForm },
    helpers: { getFieldProps, getFieldErrorProps, isFormDisabled },
  } = useForm({
    initialValues: {
      amount,
      walletAddress,
      coinBalance: selectedCoin?.disbursable ?? '',
      cabId: selectedCoin ? getUniqueIdentifierForCustodialAccountBalanceModel(selectedCoin) : '',
      destinationTag,
    },
    yupShape: {
      amount: cryptoWithdrawalAmountRule(lockAmountError),
      walletAddress: cryptoWithdrawalWalletAddressRule,
      coinBalance: requiredNumberRule,
      cabId: requiredStringRule,
      destinationTag: destinationTagRule,
    },
    onSubmit: (formValues, { setSubmitting }) => {
      setSubmitting(false);
      onReview(formValues);
    },
  });

  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }) => {
    clearLockError?.();
    setFieldValue('amount', event.target.value);
  };

  const onCoinDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value as string;

    const chosenCoin = coinList.find(cab => getUniqueIdentifierForCustodialAccountBalanceModel(cab) === id)!;

    setValues(val => ({
      ...val,
      cabId: id,
      coinBalance: chosenCoin.disbursable,
    }));
  };

  const onWalletAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('walletAddress', e.target.value.trim());
  };

  const onDestinationTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('destinationTag', e.target.value);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockAmountError]);

  return (
    <FlowFirstPageLayout
      title={t('elements.transfer.customizableLabels.withdrawalCryptoAmountPageTitle')}
      isContentCentered={getCoinListError || unlockAmountError}
      isAbove
      onBack={onBack}
      onClose={onClose}
      footerButtons={
        !getCoinListError &&
        !unlockAmountError && (
          <Button
            type="submit"
            isLoading={lockingAmount}
            disabled={isFormDisabled || !Number(values.amount)}
            onClick={submitForm}
          >
            <Typography variant="buttonLarge">
              {t('elements.transfer.withdrawalCryptoAmountPage.reviewButton')}
            </Typography>
            <ArrowForwardIcon />
          </Button>
        )
      }
    >
      {getCoinListError || unlockAmountError ? (
        <ErrorState onReload={onReload} />
      ) : (
        <>
          <Backdrop
            open={lockingAmount}
            sx={{ backgroundColor: theme.palette.background.whiteTransparent50, zIndex: 5, position: 'absolute' }}
          />
          <StyledContainer gap="32px" isMobile={isMobile}>
            <NumberInput
              decimalScale={8}
              {...getFieldProps('amount')}
              {...getFieldErrorProps('amount')}
              onChange={onAmountChange}
              placeholder="0.0"
              label={t('elements.transfer.withdrawalCryptoAmountPage.amount')}
            />
            <CoinsDropdown
              {...getFieldProps('cabId')}
              {...getFieldErrorProps('cabId')}
              onChange={onCoinDropdownChange}
              fullWidth
              coinsList={coinList}
              withSearch={coinList.length > (isMobile ? 5 : 8)}
              renderValueHandler={renderSelectedValue(coinList)}
            />
            <WalletAddressSection
              walletAddressProps={{
                ...getFieldProps('walletAddress'),
                ...getFieldErrorProps('walletAddress'),
                onChange: onWalletAddressChange,
              }}
              destinationTagProps={{
                ...getFieldProps('destinationTag'),
                ...getFieldErrorProps('destinationTag'),
                onChange: onDestinationTagChange,
              }}
            />
          </StyledContainer>
        </>
      )}
    </FlowFirstPageLayout>
  );
};

export default CryptoAmountPage;
