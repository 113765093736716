import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import WAValidator from 'multicoin-address-validator/dist/wallet-address-validator';
import * as Yup from 'yup';
import { NumberSchema, StringSchema, number, string } from 'yup';
import getSanitizedWalletAddress from 'elements/element-transfer/utils/getSanitizedWalletAddress';
import { getParsedCoinAssetType } from '../utils/getParsedCoinAssetType';

const YupString = string().trim();

export const MAX_ACCOUNT_NUMBER_LENGTH = 35;
export const ROUTING_NUMBER_LENGTH = 9;
export const ZIP_CODE_LENGTH = 5;
export const MAX_FULL_NAME_LENGTH = 100;
export const MAX_STREET1_LENGTH = 100;
export const MAX_STREET2_LENGTH = 100;
export const MAX_CITY_LENGTH = 35;
export const MAX_STATE_NON_US_LENGTH = 35;
export const MIN_POSTAL_CODE_LENGTH = 4;
export const MAX_POSTAL_CODE_LENGTH = 34;
export const MIN_SWIFT_CODE_LENGTH = 8;
export const MAX_SWIFT_CODE_LENGTH = 11;
export const MAX_MEMO_LENGTH = 140;
export const MIN_PURPOSE_OF_PAYMENT = 5;
export const MAX_PURPOSE_OF_PAYMENT = 140;
export const MAX_DESTINATION_TAG_LENGTH = 100;
export const MAX_INTERNAL_TRANSFER_COMMENT = 50;
export const MIN_INTERNAL_TRANSFER_COMMENT = 1;

interface YupRules {
  baseOptionalRule: StringSchema;
  accountNumberRule: StringSchema;
  confirmAccountNumberRule: (accountNumber: string) => StringSchema;
  routingNumberRule: StringSchema;
  swiftCodeRule: StringSchema;
  destinationTagRule: StringSchema;
  fullNameRule: StringSchema;
  countryRule: StringSchema;
  locationTypeRule: StringSchema;
  street1Rule: StringSchema;
  street2Rule: (errorText: string) => StringSchema;
  cityRule: StringSchema;
  stateUSRule: StringSchema;
  stateNonUSRule: StringSchema;
  zipCodeRule: StringSchema;
  bankNameRule: StringSchema;
  postalCodeRule: StringSchema;
  requiredStringRule: StringSchema;
  cryptoWithdrawalAmountRule: (isAmountError: boolean) => StringSchema;
  cryptoWithdrawalWalletAddressRule: StringSchema;
  requiredNumberRule: NumberSchema;
  wireWithdrawalAmountRule: (maxAmount: number, isAmountError: boolean) => StringSchema;
  purposeOfPaymentRule: StringSchema;
  internalTransferCommentRule: StringSchema;
}

export const useYupRules = (): YupRules => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      baseOptionalRule: YupString.optional(),
      accountNumberRule: YupString.required(t('elements.transfer.withdrawalWireValidation.accountNumber')).max(
        MAX_ACCOUNT_NUMBER_LENGTH,
        t('elements.transfer.withdrawalWireValidation.accountNumber'),
      ),
      confirmAccountNumberRule: accountNumber =>
        YupString.required(t('elements.transfer.withdrawalWireValidation.confirmAccountNumber')).oneOf(
          [Yup.ref(accountNumber)],
          t('elements.transfer.withdrawalWireValidation.confirmAccountNumber'),
        ),
      routingNumberRule: YupString.required(t('elements.transfer.withdrawalWireValidation.routingNumber'))
        .matches(/^[0-9]+$/, t('elements.transfer.withdrawalWireValidation.routingNumber'))
        .min(ROUTING_NUMBER_LENGTH, t('elements.transfer.withdrawalWireValidation.routingNumber'))
        .max(ROUTING_NUMBER_LENGTH, t('elements.transfer.withdrawalWireValidation.routingNumber')),
      swiftCodeRule: YupString.required(t('elements.transfer.withdrawalWireValidation.swiftCode'))
        .min(MIN_SWIFT_CODE_LENGTH, t('elements.transfer.withdrawalWireValidation.swiftCode'))
        .max(MAX_SWIFT_CODE_LENGTH, t('elements.transfer.withdrawalWireValidation.swiftCode')),
      destinationTagRule: YupString.max(
        MAX_DESTINATION_TAG_LENGTH,
        t('elements.transfer.withdrawalCryptoAmountPage.destinationTagError'),
      ),
      fullNameRule: YupString.required(t('elements.transfer.withdrawalWireValidation.fullName')).max(
        MAX_FULL_NAME_LENGTH,
        t('elements.transfer.withdrawalWireValidation.fullName'),
      ),
      countryRule: YupString.required(),
      locationTypeRule: YupString.required(),
      street1Rule: YupString.required(t('elements.transfer.withdrawalWireValidation.street1')).max(
        MAX_STREET1_LENGTH,
        t('elements.transfer.withdrawalWireValidation.street1'),
      ),
      street2Rule: (errorText: string) => YupString.optional().max(MAX_STREET2_LENGTH, errorText),
      cityRule: YupString.required(t('elements.transfer.withdrawalWireValidation.city')).max(
        MAX_CITY_LENGTH,
        t('elements.transfer.withdrawalWireValidation.city'),
      ),
      stateUSRule: YupString.required(t('elements.transfer.withdrawalWireValidation.state')),
      stateNonUSRule: YupString.required(t('elements.transfer.withdrawalWireValidation.state')).max(
        MAX_STATE_NON_US_LENGTH,
        t('elements.transfer.withdrawalWireValidation.state'),
      ),
      zipCodeRule: YupString.required(t('elements.transfer.withdrawalWireValidation.zipCode'))
        .matches(/^[0-9]+$/, t('elements.transfer.withdrawalWireValidation.zipCode'))
        .min(ZIP_CODE_LENGTH, t('elements.transfer.withdrawalWireValidation.zipCode'))
        .max(ZIP_CODE_LENGTH, t('elements.transfer.withdrawalWireValidation.zipCode')),
      bankNameRule: YupString.required(t('elements.transfer.withdrawalWireValidation.bankName')),
      postalCodeRule: YupString.required(t('elements.transfer.withdrawalWireValidation.postalCode'))
        .matches(/^[a-zA-Z0-9 ]*$/, t('elements.transfer.withdrawalWireValidation.postalCode'))
        .min(MIN_POSTAL_CODE_LENGTH, t('elements.transfer.withdrawalWireValidation.postalCode'))
        .max(MAX_POSTAL_CODE_LENGTH, t('elements.transfer.withdrawalWireValidation.postalCode')),
      requiredStringRule: YupString.required(),
      requiredNumberRule: number().required(),
      cryptoWithdrawalAmountRule: (isAmountError: boolean) =>
        YupString.test(
          'insufficientFundsError',
          t('elements.transfer.withdrawalCryptoAmountPage.insufficientFundsError'),
          (amount, context) => !amount || Number(amount) <= context.parent.coinBalance,
        ).test('limitsError', t('elements.transfer.withdrawalWireAmountPage.initLimitsError'), () => !isAmountError),
      cryptoWithdrawalWalletAddressRule: YupString.test(
        'walletAddressError',
        t('elements.transfer.withdrawalCryptoAmountPage.walletAddressError'),
        async (walletAddress, context) => {
          const { cabId } = context.parent;

          if (!cabId) {
            return false;
          }

          const coinAssetType = getParsedCoinAssetType(cabId);

          if (walletAddress && coinAssetType) {
            const sanitizedWalletAddress = getSanitizedWalletAddress(walletAddress, coinAssetType);

            try {
              return WAValidator.validate(sanitizedWalletAddress, coinAssetType, 'both');
            } catch (e) {
              try {
                const module = await import('@swyftx/api-crypto-address-validator/dist/wallet-address-validator');
                return module.validate(sanitizedWalletAddress, coinAssetType, 'both');
              } catch (error) {
                try {
                  const module = await import('crypto-address-validator-ts');
                  return module.validate(sanitizedWalletAddress, coinAssetType, {
                    networkType: 'both',
                    chainType: 'both',
                  });
                } catch (err) {
                  return true;
                }
              }
            }
          }
          return false;
        },
      ),
      wireWithdrawalAmountRule: (maxAmount: number, isAmountError: boolean) =>
        YupString.test(
          'insufficientFundsError',
          t('elements.transfer.withdrawalWireAmountPage.insufficientFundsError'),
          amount => !amount || (maxAmount > 0 && Number(amount) <= maxAmount),
        ).test('limitsError', t('elements.transfer.withdrawalWireAmountPage.initLimitsError'), () => !isAmountError),
      purposeOfPaymentRule: YupString.required(t('elements.transfer.withdrawalWireValidation.purposeOfPaymentRequired'))
        .min(MIN_PURPOSE_OF_PAYMENT, t('elements.transfer.withdrawalWireValidation.purposeOfPaymentMin'))
        .max(MAX_PURPOSE_OF_PAYMENT, t('elements.transfer.withdrawalWireValidation.purposeOfPaymentMax')),
      internalTransferCommentRule: YupString.min(
        MIN_INTERNAL_TRANSFER_COMMENT,
        t('elements.transfer.internalTransferValidation.comment'),
      ).max(MAX_INTERNAL_TRANSFER_COMMENT, t('elements.transfer.internalTransferValidation.comment')),
    }),
    [t],
  );
};
