import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { RecipientCustodialAccount } from 'elements/types';

import { ReactComponent as NoResultsIcon } from 'elements/element-transfer/assets/icons/no_results.svg';
import { BaseInput, Button, CircularProgress } from 'elements/components';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';
import BaseRadioGroup from 'elements/element-transfer/components/BaseRadioGroup';
import RadioLabel from './RadioLabel';

import { StyledButton, StyledLoaderContainer } from './styled';

type Props = {
  search: string;
  recipients: Array<RecipientCustodialAccount>;
  isRecipientsLoading: boolean;
  recipientId?: string;
  lastItemRef: React.MutableRefObject<HTMLDivElement | null>;
  onSearch: (value: string) => void;
  onClose: () => void;
  onBack: () => void;
  onContinue: (id: string) => void;
};

const InternalTransferRecipientSearchPage: React.FC<Props> = ({
  search,
  recipients,
  isRecipientsLoading,
  recipientId: id,
  lastItemRef,
  onSearch,
  onClose,
  onBack,
  onContinue,
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState(search);
  const [recipientId, setRecipientId] = useState(id || '');
  const formattedRadioValues = useMemo(
    () => recipients.map(rec => ({ value: rec.id, label: rec.id, data: rec })),
    [recipients],
  );

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onSearchClick = () => {
    const trimmedValue = searchValue.trim();
    setSearchValue(trimmedValue);
    setRecipientId('');
    onSearch(trimmedValue);
  };

  const onRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) => setRecipientId(e.target.value);

  return (
    <FlowFirstPageLayout
      title={t('elements.transfer.customizableLabels.internalTransferRecipientSearchPageTitle')}
      onClose={onClose}
      onBack={onBack}
      isAbove
      footerButtons={
        <Stack gap="16px">
          <Button type="submit" disabled={!recipientId} onClick={() => onContinue(recipientId)}>
            <Typography variant="buttonLarge">{t('elements.common.continue')}</Typography>
            <ArrowForwardIcon />
          </Button>
        </Stack>
      }
    >
      <Stack gap="16px" mb="24px">
        <Typography variant="overlineDefaultMedium" color="secondary.font">
          {t('elements.transfer.internalTransferRecipientSearchPage.subtitle')}
        </Typography>
        <Stack direction="row" gap="18px" marginBottom="24px">
          <BaseInput
            name="searchExternalAccount"
            placeholder={t('elements.transfer.internalTransferRecipientSearchPage.searchInputPlaceholder')}
            value={searchValue}
            onChange={onSearchInputChange}
            helperText={t('elements.transfer.internalTransferRecipientSearchPage.helperText')}
          />
          <StyledButton disabled={!searchValue.trim().length} onClick={onSearchClick}>
            <Typography variant="buttonLarge">
              {t('elements.transfer.internalTransferRecipientSearchPage.searchBtnTitle')}
            </Typography>
          </StyledButton>
        </Stack>
        {search && !isRecipientsLoading && (
          <Typography variant="overlineDefaultMedium" color="secondary.font">
            {t('elements.transfer.internalTransferRecipientSearchPage.results')}
          </Typography>
        )}

        {search && !isRecipientsLoading && !recipients.length && (
          <Stack alignItems="center">
            <NoResultsIcon />
            <Typography variant="h6" color="grey.500">
              {t('elements.transfer.internalTransferRecipientSearchPage.noResultsFound')}
            </Typography>
            <Typography variant="bodyDefaultBook" color="secondary.font">
              {t('elements.transfer.internalTransferRecipientSearchPage.pleaseTryAgain')}
            </Typography>
          </Stack>
        )}

        {search && !!recipients.length && (
          <>
            <BaseRadioGroup
              labelledby="recipients-radio-group"
              name="recipients"
              value={recipientId}
              onChange={onRecipientChange}
              values={formattedRadioValues}
              radioLabelNode={RadioLabel}
              withHover
            />
            <div id="intersection-observer-trigger" ref={lastItemRef} />
          </>
        )}

        {isRecipientsLoading && (
          <StyledLoaderContainer>
            <CircularProgress />
          </StyledLoaderContainer>
        )}
      </Stack>
    </FlowFirstPageLayout>
  );
};

export default InternalTransferRecipientSearchPage;
